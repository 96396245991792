<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-form-group class="col-12" :label="$t('name')">

                        <b-input-group class="mb-3" prepend="TR">
                            <div class="label-as-input">{{ isNotNullValue(formData.name) }}</div>
                        </b-input-group>

                        <b-input-group prepend="EN">
                            <div class="label-as-input">{{ isNotNullValue(formData.name_en) }}</div>
                        </b-input-group>


                </b-form-group>
                <b-form-group class="col-12" :label="$t('address')">
                        <div class="label-as-input">{{ isNotNullValue(formData.address) }}</div>


                        <b-input-group prepend="EN">
                            <div class="label-as-input">{{ isNotNullValue(formData.address_en) }}</div>
                        </b-input-group>
                </b-form-group>

            </b-row>

        </ValidationObserver>
    </div>
</template>

<script>


    import CampusService from "@/services/CampusService";

    export default {
        components: {
          CampusService
        },
        props: {
            formId: {
                type: Number,
            }
        },
        data() {
            return {
                formLoading: false,
                formData: {}

            }
        },

        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                this.formLoading = true;
                CampusService.get(id)
                             .then((response) => {
                                 this.formData = response.data.data;
                                 this.formLoading = false;
                             })
                             .catch((error) => {
                                 if (error.data.message) {
                                     this.$toast.error(this.$t("api." + error.data.message));
                                 }
                             });
            },
        }
    }
</script>
